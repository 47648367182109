import React from "react";
import { BellOutlined } from "@ant-design/icons";
import { Notification } from "@koble/graphql";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { timeAgo } from "@koble/utils/src";
import { Avatar, Checkbox, Flex, List, theme, Typography } from "antd";

const { Text } = Typography;

const CommonNotificationListItem = ({
  notification,
  isChecked,
  onCheck,
  selectedColor,
}: {
  notification: Notification;
  isChecked?: boolean;
  onCheck?: (notificationId: number) => void;
  selectedColor?: string;
}) => {
  const { token } = theme.useToken();
  const { isMobile } = useIsMobile();

  return (
    <List.Item
      style={{
        paddingRight: 12,
        paddingLeft: 12,
        alignItems: "baseline",
        transition: "background-color 0.3s",
        ...(onCheck && {
          paddingRight: isMobile ? 26 : 34,
          paddingLeft: isMobile ? 26 : 34,
        }),
        ...(notification.isRead && { backgroundColor: "#F5F5F5" }),
        ...(isChecked && selectedColor && { backgroundColor: selectedColor }),
      }}
    >
      {onCheck && (
        <Checkbox
          style={{ marginRight: "10px" }}
          checked={isChecked}
          onChange={() => onCheck(notification.notificationId)}
        />
      )}
      <List.Item.Meta
        avatar={
          <Avatar
            style={{ backgroundColor: "#F5F5F5" }}
            src={notification.imageUrl}
            size="large"
          >
            <BellOutlined style={{ fontSize: 14, color: "#606060" }} />
          </Avatar>
        }
        title={
          <Flex justify="space-between">
            <Text style={{ fontWeight: notification.isRead ? "600" : "700" }}>
              {notification.title}
            </Text>

            {notification.redirectUrl && (
              <a
                key={notification.notificationId}
                href={notification.redirectUrl}
                style={{ color: token.colorPrimary, fontWeight: "normal" }}
              >
                {notification.actionLabel || "Ver"}
              </a>
            )}
          </Flex>
        }
        description={
          <>
            <Text
              style={{
                display: "block",
                marginBottom: 2,
                ...(!notification.isRead && { fontWeight: 600 }),
              }}
            >
              {notification.message}
            </Text>
            <Text
              style={{
                color: "#00000073",
                fontSize: 12,
              }}
            >
              {timeAgo.format(new Date(notification.dateTime))}
            </Text>
          </>
        }
      />
    </List.Item>
  );
};

export default CommonNotificationListItem;

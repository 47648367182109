import React, { ReactNode } from "react";
import { useCookie } from "@koble/hooks";
import { Badge } from "antd";
import { ItemType } from "antd/es/menu/interface";

import CommonLayoutContext, {
  BannerItemType,
  CommonLayoutMenuItemType,
} from "./CommonLayoutContext";

const CommonLayoutProvider = ({
  children,
  defaultCollapsed,
  defaultSideBarWidth,
  leftAddons,
  rightAddons,
  mobileLeftAddons,
  mobileRightAddons,
  smallLogoUrl,
  largeLogoUrl,
  upperMenuItems,
  lowerMenuItems,
  selectedMenuKeys,
  openKeys,
  avatarComponent,
  banners,
  maxWidth,
  isMobile,
  showSideBar,
}: {
  children: ReactNode;
  defaultCollapsed?: boolean;
  defaultSideBarWidth?: number;
  leftAddons?: React.ReactNode[];
  rightAddons?: React.ReactNode[];
  mobileLeftAddons?: React.ReactNode[];
  mobileRightAddons?: React.ReactNode[];
  smallLogoUrl: string;
  largeLogoUrl: string;
  upperMenuItems?: ItemType<CommonLayoutMenuItemType>[];
  lowerMenuItems?: ItemType[];
  selectedMenuKeys?: string[];
  openKeys?: string[];
  avatarComponent?: React.ReactNode;
  banners?: BannerItemType[];
  maxWidth?: number;
  isMobile: boolean;
  showSideBar?: boolean;
}) => {
  const [collapsed, setCollapsed] = useCookie(
    "collapsed",
    defaultCollapsed ? "true" : "false"
  );

  const upperMenuItemsWithBadges = (
    upperMenuItems as CommonLayoutMenuItemType[]
  )?.map((item) => {
    if (!item) return item;

    const { badgeCount, ...rest } = item;

    if (item.badgeCount) {
      return {
        ...rest,
        itemIcon: <Badge count={badgeCount} />,
        icon:
          collapsed === "true" && !isMobile ? (
            <Badge count={badgeCount} dot offset={[5, 10]}>
              {item.icon}
            </Badge>
          ) : (
            item.icon
          ),
        label: (
          <>
            {item.label}
            {`\u00A0\u00A0`}
          </>
        ),
      };
    }
    return item;
  });

  return (
    <CommonLayoutContext.Provider
      value={{
        collapsed: collapsed === "true",
        setCollapsed: (c: boolean) => {
          setCollapsed(c.toString());
        },
        sideBarWidth: defaultSideBarWidth || 236,
        leftAddons,
        rightAddons,
        mobileLeftAddons,
        mobileRightAddons,
        smallLogoUrl,
        largeLogoUrl,
        upperMenuItems,
        upperMenuItemsWithBadges,
        lowerMenuItems,
        selectedMenuKeys,
        openKeys,
        avatarComponent,
        banners,
        maxWidth,
        isMobile,
        showSideBar: showSideBar ?? true,
      }}
    >
      {children}
    </CommonLayoutContext.Provider>
  );
};

export default CommonLayoutProvider;
